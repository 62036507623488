<template>
  <div class="contentWholeWrap">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="searchBar"
    >
      <h2
        class="headline font-weight-medium"
        @click="$refs.buttonSearch.click()"
      >
        {{ $t('Role.list_title') }}
      </h2>
      <v-spacer class="hidden-xs-only" />
      <x-formgroup
        v-model="scheme.valid"
        class="d-box-md-and-up w100-md-and-down"
      >
        <x-textfield
          v-model="scheme.userName.value"
          v-bind="scheme.userName"
          class="small"
          @keyup.enter="() => $refs.buttonSearch.click()"
        />
        <x-textfield
          v-model="scheme.userEmail.value"
          v-bind="scheme.userEmail"
          class="small"
          @keyup.enter="() => $refs.buttonSearch.click()"
        />
        <x-button
          ref="buttonSearch"
          v-bind="buttonSearch"
          class="btnSecondary btnXsBlock"
        />
      </x-formgroup>
    </v-layout>
    <!-- END header -->

    <!-- BEGIN content -->
    <div class="contentBoxWrap">
      <div class="contentBox">
        <v-layout
          row
          wrap
        >
          <x-button
            v-bind="buttonCreate"
            class="btnPrimary btnXsBlock"
          />
          <v-spacer class="hidden-xs-only" />

          <!-- BEGIN filter -->
          <x-formgroup
            v-model="scheme.valid"
            class="table-filters d-box-md-and-up w100-md-and-down"
          >
            <span class="mr-2 labelStyle">Filter</span>
            <x-select-filter
              v-model="filterUserType.value"
              v-bind="filterUserType"
              class="small"
              @change="() => fetchUserList()"
            />
          </x-formgroup>
          <!-- END filter -->
        </v-layout>

        <x-table
          v-bind="tableScheme"
          :items="roleList"
          class="mt-3 roleList"
        />

        <pagination
          ref="pager"
          :query="query"
          :fetch="$Role.fetchUserList"
          :callback="pagerCallback"
        />
      </div>
    </div>
    <!-- END content -->
  </div>
</template>

<script>
import { getReadableRoles } from '../../../auth/config'
import { formatDatetimeFromISOString } from '../../../utils/date'
import Pagination from '@/components/Pagination'

export default {
  components: { Pagination },
  data () {
    return {
      query: {},
      // BEGIN search
      scheme: {
        userName: {
          type: 'text',
          name: 'roleName',
          placeholder: this.$t('User.name')
        },
        userEmail: {
          type: 'text',
          name: 'roleEmail',
          placeholder: this.$t('User.email')
        }
      },

      buttonSearch: {
        content: 'Search',
        size: 'small',
        click: () => this.fetchUserList()
      },
      // END search

      // BEGIN filter
      filterUserType: {
        items: [
          {
            text: this.$t('Role.select_all'),
            value: null
          }
        ].concat(
          getReadableRoles(this.$store.user.role).map(id => ({
            text: this.$t(`Role.types[${id}]`),
            value: id.toString()
          }))
        ),
        value: null
      },
      // END filter

      buttonCreate: {
        content: this.$t('User.create'),
        size: 'medium',
        prependIcon: 'add',
        click: () => {
          this.$router.push({ name: 'RoleCreate' })
        }
      },
      users: [],
      tableScheme: {
        multiSelectable: false,
        headers: [
          { value: 'no', text: 'No' },
          { value: 'name', text: this.$t('User.name') },
          { value: 'email', text: this.$t('User.email') },
          { value: 'role', text: this.$t('User.role') },
          { value: 'setupTime', text: this.$t('User.create_time') },
          { value: 'remark', text: this.$t('User.remark') },
          { value: 'action', text: 'Actions' }
        ],
        actions: [
          {
            // content: 'delete_outline',
            // square: true,
            content: 'Delete',
            outline: true,
            class: 'actionWarning',
            click: this.deleteUserIntent
          }
        ]
      }
    }
  },
  computed: {
    roleList () {
      return this.users.map((user, ind) => {
        return {
          no: this.$refs.pager.offset + ind + 1,
          ...user,
          role: this.$t(`Role.types[${user.roleId}]`),
          setupTime: formatDatetimeFromISOString(user.createTime)
        }
      })
    }
  },
  async mounted () {
    await this.fetchUserList()
  },
  methods: {
    pagerCallback ({ users }) {
      this.users = users
    },
    async fetchUserList (pageIndex = 1) {
      this.query = {
        name: this.scheme.userName.value,
        email: this.scheme.userEmail.value,
        role: this.filterUserType.value
      }
    },
    async deleteUserIntent (user) {
      this.$store.confirmDialog.push({
        message: 'Are you sure to remove this user? This cannot be undone.',
        onConfirm: () => this.deleteUser(user.id)
      })
    },
    async deleteUser (userId) {
      this.$store.loading = true
      try {
        await this.$Role.deleteUser({
          userId
        })
        this.$store.successNotification = 'User has been successfully removed'
        await this.$refs.pager.refresh()
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped></style>
