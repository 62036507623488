import { render, staticRenderFns } from "./CreateRole.vue?vue&type=template&id=f21d7ab6&scoped=true&"
import script from "./CreateRole.vue?vue&type=script&lang=js&"
export * from "./CreateRole.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f21d7ab6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f21d7ab6')) {
      api.createRecord('f21d7ab6', component.options)
    } else {
      api.reload('f21d7ab6', component.options)
    }
    module.hot.accept("./CreateRole.vue?vue&type=template&id=f21d7ab6&scoped=true&", function () {
      api.rerender('f21d7ab6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/Role/pages/CreateRole.vue"
export default component.exports