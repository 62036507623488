<template>
  <div class="contentWholeWrap">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="mb-4"
    >
      <h2 class="headline font-weight-medium">
        Create User
      </h2>
    </v-layout>
    <!-- END header -->

    <!-- BEGIN content -->
    <div class="contentBoxWrap">
      <div class="contentBox">
        <v-layout>
          <v-flex
            md6
            offset-md3
          >
            <x-formgroup
              ref="form"
              v-model="scheme.valid"
              class="w100"
              @submit.prevent="() => $refs.submitButton.click()"
            >
              <!-- BEGIN input group -->
              <div class="pa-2">
                <x-select-filter
                  v-model="scheme.userType.value"
                  v-bind="scheme.userType"
                />
                <x-textfield
                  v-model="scheme.userName.value"
                  v-bind="scheme.userName"
                />
                <x-textfield
                  v-model="scheme.email.value"
                  v-bind="scheme.email"
                />
                <x-textfield
                  v-model="scheme.password.value"
                  v-bind="scheme.password"
                />
                <x-textfield
                  v-model="scheme.passwordConfirm.value"
                  v-bind="scheme.passwordConfirm"
                />
              </div>
              <!-- END input group -->

              <v-layout class="actionBtnGroup">
                <x-button
                  v-bind="scheme.buttonCancel"
                  class="btnInfo"
                />
                <x-button
                  ref="submitButton"
                  v-bind="scheme.buttonSave"
                  class="btnSecondary"
                />
              </v-layout>
            </x-formgroup>
          </v-flex>
        </v-layout>
      </div>
    </div>
    <!-- END content -->
  </div>
</template>

<script>
import { getCreatableRoles } from '../../../auth/config'
import {
  notEmpty,
  minLength,
  maxLength,
  isEmail,
  passwordFormat,
  validEmailDomain
} from '../../../utils/validation'

export default {
  data () {
    return {
      scheme: {
        userName: {
          label: this.$t('User.name'),
          placeholder: 'maximum length 50 characters',
          value: '',
          rules: [maxLength(50)].map(x => x.bind(this))
        },

        userType: {
          label: this.$t('User.role'),
          placeholder: 'select user type',
          items: getCreatableRoles(this.$store.user.role).map(id => ({
            text: this.$t(`Role.types[${id}]`),
            value: id.toString()
          })),
          value: '',
          required: true,
          rules: [notEmpty, maxLength(50)].map(x => x.bind(this))
        },

        email: {
          label: this.$t('User.email'),
          value: '',
          appendIcon: 'email',
          placeholder: 'enter user email',
          required: true,
          rules: [
            notEmpty,
            maxLength(50),
            isEmail,
            validEmailDomain(this.$store.user.role)
          ].map(x => x.bind(this))
        },

        password: {
          label: 'Set Password',
          type: 'password',
          placeholder:
            'enter 8-20 characters, includes at least 1 alphabet & 1 number',
          appendIcon: 'lock',
          value: '',
          required: true,
          rules: [notEmpty, minLength(8), maxLength(20), passwordFormat].map(
            x => x.bind(this)
          )
        },

        passwordConfirm: {
          label: 'Confirm Password',
          type: 'password',
          placeholder: 'confirm password',
          appendIcon: 'lock',
          value: '',
          required: true
        },

        buttonCancel: {
          content: 'Cancel',
          size: 'large',
          outline: true,
          click: () => {
            this.$router.go(-1)
          }
        },

        buttonSave: {
          content: 'Save',
          size: 'large',
          click: this.submit
        }
      }
    }
  },
  methods: {
    validate () {
      const password = this.scheme.password.value
      if (password !== this.scheme.passwordConfirm.value) {
        throw new Error('Password and confirm password not match')
      }
      if (!this.$refs.form.$children[0].validate()) {
        throw new Error('Some fields needs to be fixed')
      }
    },
    async submit () {
      this.$store.loading = true
      try {
        this.validate()
        await this.$Role.createUser({
          manager: this.$store.user.email,
          name: this.scheme.userName.value,
          email: this.scheme.email.value.toLowerCase(),
          roleId: this.scheme.userType.value,
          password: this.scheme.password.value
        })
        this.$store.successNotification = 'User successfully created'
        this.$router.replace({ name: 'RoleList' })
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped></style>
